var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fill-height"},[_c('div',{staticClass:"background-1",staticStyle:{"height":"100%"}},[_c('v-container',{staticClass:"container py-12",staticStyle:{"position":"relative"}},[_c('card-hover',{staticClass:"px-6 py-12",attrs:{"disable-hover":true}},[_c('div',{class:_vm.$vuetify.breakpoint.smAndUp ? 'my-12' : ''},[_c('div',{staticClass:"d-flex justify-center text-center"},[_c('div',{staticClass:"d-flex flex-column flex-cols-3 align-center"},[_c('div',{staticClass:"text-h3 font-weight-bold mb-5",class:_vm.$vuetify.theme.dark ? 'primary--text' : 'black--text'},[_vm._v(" Coming soon! ")]),_c('div',{staticClass:"medium-text mb-10 font-weight-medium grey1--text",staticStyle:{"max-width":"400px"}},[_vm._v(" We are launching soon, stay tuned! ")])])]),_c('v-card',{staticClass:"pa-4 d-flex justify-space-between flex-sm-row flex-column rounded-lg",class:{
              'mt-10': _vm.$vuetify.breakpoint.smAndUp,
              'footer-light': !_vm.$vuetify.theme.dark,
              'footer-dark': _vm.$vuetify.theme.dark,
            },attrs:{"d-flex":""}},[_c('div',{staticClass:"text-body-1 align-self-center"},[_vm._v("Follow Waggle to get notification as soon as we launch!")]),_c('div',{staticClass:"d-flex align-center",class:{
                'justify-end': _vm.$vuetify.breakpoint.smAndUp,
                'justify-start': _vm.$vuetify.breakpoint.sm,
              }},[_c('a',{staticClass:"circle-icon",class:_vm.$vuetify.theme.dark ? 'circle-bg-dark' : 'circle-bg-light',attrs:{"href":"https://medium.com/@wagglenetwork"}},[_c('v-icon',{attrs:{"color":_vm.$vuetify.theme.dark ? 'primary lighten-2' : 'white',"small":""}},[_vm._v(" fab fa-medium-m ")])],1),_c('a',{staticClass:"ml-4 ml-sm-8 circle-icon",class:_vm.$vuetify.theme.dark ? 'circle-bg-dark' : 'circle-bg-light',attrs:{"href":"https://t.me/waggle_network"}},[_c('v-icon',{attrs:{"color":_vm.$vuetify.theme.dark ? 'primary lighten-2' : 'white'}},[_vm._v(" fab fa-telegram-plane ")])],1),_c('a',{staticClass:"ml-4 ml-sm-8 circle-icon",class:_vm.$vuetify.theme.dark ? 'circle-bg-dark' : 'circle-bg-light',attrs:{"href":"https://twitter.com/wagglenetwork"}},[_c('v-icon',{attrs:{"color":_vm.$vuetify.theme.dark ? 'primary lighten-2' : 'white'}},[_vm._v(" fab fa-twitter ")])],1),_c('a',{staticClass:"ml-4 ml-sm-8",attrs:{"href":"https://t.me/wagglenetwork"}},[_c('v-icon',{attrs:{"color":_vm.$vuetify.theme.dark ? 'primary' : '#ff965b',"large":""}},[_vm._v(" fab fa-telegram-plane ")])],1)])])],1),_c('div',{staticClass:"container-bg-left"}),_c('div',{staticClass:"container-bg-right"})])],1)],1),_c('company-footer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }