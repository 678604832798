<template>
  <div class="fill-height">
    <div class="background-1" style="height: 100%">
      <v-container class="container py-12" style="position: relative">
        <card-hover :disable-hover="true" class="px-6 py-12">
          <div :class="$vuetify.breakpoint.smAndUp ? 'my-12' : ''">
            <div class="d-flex justify-center text-center">
              <div class="d-flex flex-column flex-cols-3 align-center">
                <div
                  class="text-h3 font-weight-bold mb-5"
                  :class="$vuetify.theme.dark ? 'primary--text' : 'black--text'"
                >
                  Coming soon!
                </div>
                <div class="medium-text mb-10 font-weight-medium grey1--text" style="max-width: 400px">
                  We are launching soon, stay tuned!
                </div>
              </div>
            </div>
            <v-card
              d-flex
              class="pa-4 d-flex justify-space-between flex-sm-row flex-column rounded-lg"
              :class="{
                'mt-10': $vuetify.breakpoint.smAndUp,
                'footer-light': !$vuetify.theme.dark,
                'footer-dark': $vuetify.theme.dark,
              }"
            >
              <div class="text-body-1 align-self-center">Follow Waggle to get notification as soon as we launch!</div>
              <div
                class="d-flex align-center"
                :class="{
                  'justify-end': $vuetify.breakpoint.smAndUp,
                  'justify-start': $vuetify.breakpoint.sm,
                }"
              >
                <a
                  href="https://medium.com/@wagglenetwork"
                  class="circle-icon"
                  :class="$vuetify.theme.dark ? 'circle-bg-dark' : 'circle-bg-light'"
                >
                  <v-icon :color="$vuetify.theme.dark ? 'primary lighten-2' : 'white'" small> fab fa-medium-m </v-icon>
                </a>
                <a
                  href="https://t.me/waggle_network"
                  class="ml-4 ml-sm-8 circle-icon"
                  :class="$vuetify.theme.dark ? 'circle-bg-dark' : 'circle-bg-light'"
                >
                  <v-icon :color="$vuetify.theme.dark ? 'primary lighten-2' : 'white'"> fab fa-telegram-plane </v-icon>
                </a>
                <a
                  href="https://twitter.com/wagglenetwork"
                  class="ml-4 ml-sm-8 circle-icon"
                  :class="$vuetify.theme.dark ? 'circle-bg-dark' : 'circle-bg-light'"
                >
                  <v-icon :color="$vuetify.theme.dark ? 'primary lighten-2' : 'white'"> fab fa-twitter </v-icon>
                </a>
                <a href="https://t.me/wagglenetwork" class="ml-4 ml-sm-8">
                  <v-icon :color="$vuetify.theme.dark ? 'primary' : '#ff965b'" large> fab fa-telegram-plane </v-icon>
                </a>
              </div>
            </v-card>
          </div>
          <div class="container-bg-left"></div>
          <div class="container-bg-right"></div>
        </card-hover>
      </v-container>
    </div>
    <company-footer />
  </div>
</template>
<script>
export default {
  components: {},
}
</script>

<style scoped>
.medium-text {
  font-size: 20px;
}
.container {
  max-width: 800px !important;
  min-height: 800px;
}
.footer-dark {
  background: transparent !important;
  border: 2px solid var(--v-grey1-base) !important;
  border-radius: 16px;
}
.footer-light {
  background: transparent !important;
  border: 2px solid white !important;
  border-radius: 16px;
}
.container-bg-left {
  background-size: contain;
  height: 100%;
  position: absolute;
  width: 50%;
  top: 0;
  left: 0;
  background-position: left 0 top 0;
  background: url('../../../assets/images/coming-soon-bg-left.svg') no-repeat;
  z-index: -1;
}
.container-bg-right {
  background-size: contain;
  height: 100%;
  position: absolute;
  width: 50%;
  top: 0;
  left: 50%;
  background: url('../../../assets/images/coming-soon-bg-right.svg') no-repeat;
  background-position: bottom 0 right 0;
  z-index: -2;
}
.circle-icon {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  text-align: center;
  line-height: 36px;
  vertical-align: middle;
}
.circle-bg-dark {
  background: var(--v-primary-base) !important;
}
.circle-bg-light {
  background-image: linear-gradient(to right, #ff965b, #ffba69);
}
</style>
